import React from 'react'

import Filter from './Filter'
import { createURL } from '../../utils'

const breadcrumbToSearchUrl = ({ market, submarket }) => {
  const {
    searchQueryParams,
  } = submarket ? submarket.marketPage : market.marketPage

  const hasKeys = searchQueryParams && Object.keys(searchQueryParams).length > 0

  return hasKeys && `/search${ createURL(searchQueryParams) }`
}

export default props => {
  const href = breadcrumbToSearchUrl(props)
  return href ? <Filter href={href} /> : null
}
