import React from 'react'
import styled from 'styled-components'

import Link from '../Link'

const Header = styled.h3`
  color: ${ props => props.theme.colors.darkgrey };
  font-family: ${ props => props.theme.fonts.header };
  font-size: 1.2em;
  font-weight: 700;
  padding-bottom: 10px;
`

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
`

const Item = styled.li`
  padding-right: 10px;
  font-family: ${ props => props.theme.fonts.body };
  font-size: 1.1em;
  flex: 0 0 100%;
  max-width: 100%;
  min-height: 48px;
  align-items: flex-start;
  display: flex;

  @media (min-width: 992px) {
      flex: 0 0 50%;
      max-width: 50%;
      min-height: 24px;
  }

  @media (min-width: 1200px) {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
  }
`

const RelatedLinks = ({ title = '', pages = [] }) =>
  <>
    <Header>{title}</Header>
    <List>
      {pages.map((page, i) =>
        <Item key={i}>
          <Link href={page.slug}>{page.shortTitle || page.title}</Link>
        </Item>
      )}
    </List>
  </>

const RelatedPagesContainer = styled.div`
  display: block;
  padding: ${ props => props.pages > 0 ? '30px' : 0 };

  @media (min-width: 576px) {
    padding: ${ props => props.pages > 0 ? '30px 0' : 0 };
    margin: 0;
  }
`

export default ({ relatedPages = [] }) =>
  <RelatedPagesContainer pages={relatedPages.length}>
    {relatedPages.map((related, i) =>
      <RelatedLinks key={i} {...related} />
    )}
  </RelatedPagesContainer>
