import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Main from '../components/Main'

const LandingPageTemplate = ({ data }) => {
  const {
    account: { menu, markets, ...account },
    ...page
  } = data.root.page

  page.apartments.items.forEach(apartment => {
    if (!apartment.defaultPhoto) {
      return
    }

    const images = data.images ? data.images.edges : []

    const node = images.find(edge =>
      edge.node.id === apartment.defaultPhoto.id
    )

    if (node) {
      apartment.defaultPhoto.localFile = node.node.localFile
    }
  })

  return <Layout
    account={account}
    page={page}
    menu={menu}
    markets={markets.items}
    condensedFooter={true}
  >
    <Main {...page} />
  </Layout>
}

export const query = graphql`
  query getLandingPageData($id:ID $images:[String]) {
    root {
     page: getPageById(id: $id) {
        account {
          name
          title
          googleSiteVerification
          menu {
            title
            href
            subMenu {
              title
              subTitle
              href
            }
          }

          markets(sort: [["market", "1"]]) {
            count
            items {
              title: market
              state {
                name
              }
              marketPage {
                slug
                searchQueryParams
              }
              apartments(filter: { status:published } sort: [["name", "1"]]) {
                items {
                  name
                  marketingWebsiteUrl
                }
              }
            }
          }
        }

        termGroup {
          name
          category
        }

        slug
        noindex
        hideSubmarkets
        title
        description
        h1
        copy
        breadcrumb {
          market {
            title: market
            state {
              name
            }
            marketPage {
              slug
              searchQueryParams
            }
            nonMarketPages {
              title
              shortTitle
              slug
            }
          }
          submarket {
            title: submarket
            state {
              name
            }
            marketPage {
              slug
              searchQueryParams
            }
            nonMarketPages {
              title
              shortTitle
              slug
            }
          }
          submarkets {
            title: submarket
            marketPage {
              title
              slug
              center:coordinates {
                lat
                lng
              }
              apartments(filter: { status:published } sort: [["name", "1"]]) {
                count
              }
            }
          }
        }
        center:coordinates {
          lat
          lng
        }
        zoom: mapZoom

        apartments(
          filter: { status:published }
          sort: [["spotlight", "-1"], ["name", "1"]]
        ) {
          count
          items {
            name
            prospectPhoneNumber
            address {
              line1
              city
              state
              postalCode
            }
            coordinates {
              lat
              lng
            }
            adLabel
            marketingWebsiteUrl
            defaultPhoto {
              id: publicId
              url
              alt
              title
            }
            floorPlans {
              name
              bedrooms
              availability {
                floorplan: FloorplanName
                unitId: UnitID
                bedrooms: UnitBedrooms
                unitRent: UnitRent
                marketRent: MarketRent
                dateAvailable: DateAvailable
              }
            }
          }
        }
      }
    }

    images: allLandingPageImage(filter:{landingPageId:{in:$images}}) {
      edges {
        node {
          id: landingPageId
          localFile {
            childImageSharp {
              fluid(maxWidth: 850 maxHeight: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default LandingPageTemplate
