import React, { useState } from 'react'
import { useMedia } from 'use-media'
import styled from 'styled-components'

import ApartmentCard from '../ApartmentCard'
import Well from './Well'
import Map from '../Map'
import Toggle from '../Map/Toggle'
import Breadcrumbs from './Breadcrumbs'
import { Title, SubTitle } from './Text'

import Container from './Container'
import * as Grid from '../Grid'

import RelatedLinks from './RelatedLinks'

const getMarket = breadcrumb =>
  breadcrumb.submarket ? breadcrumb.submarket.title : breadcrumb.market.title

const PricingDisclaimer = styled.div`
  color: ${ props => props.theme.main.bodyColor };
  font-size: 1em;
`

const Main = ({
  breadcrumb,
  h1,
  copy,
  apartments,
  center,
  zoom,
  hideSubmarkets,
}) => {
  const [showMap, setShowMap] = useState(false)
  const [flyTo, setFlyTo] = useState()
  const [loaded, setLoaded] = useState(false)

  const submarkets = hideSubmarkets ? []
    : breadcrumb.submarkets.filter(submarket =>
      breadcrumb.submarket &&
      submarket.marketPage &&
      submarket.title !== breadcrumb.submarket.title
    )

  const relatedPages = ['submarket', 'market'].map(key => {
    if (breadcrumb[key]) {
      const { title, nonMarketPages: pages } = breadcrumb[key]
      return { title, pages }
    }
  }).filter(item => item && item.pages && item.pages.length > 0)

  const toggleMap = () => {
    setLoaded(true)
    setShowMap(!showMap)
  }

  // always show map for tablet and larger devices
  const alwaysShowMap = useMedia({ minWidth: 768 })

  return <>
    <Toggle as='button' onClick={toggleMap} isOpen={showMap} />
    <Container.Map show={showMap || alwaysShowMap}>
      {loaded || alwaysShowMap ? <Map
        flyTo={flyTo}
        center={center}
        zoom={zoom}
        apartments={apartments.items}
        submarkets={submarkets}
        toggleMap={toggleMap}
        onOpenPopup={apt => setFlyTo(apt)}
        onClosePopup={() => setFlyTo()}
      /> : null}
    </Container.Map>
    <Grid.Container>
      <Container.Apartments>
        <Grid.Col>
          <Breadcrumbs {...breadcrumb} />
          <Title>
            {h1}
          </Title>
          <SubTitle>
            Viewing {apartments.count}
            {' apartment' + (apartments.count === 1 ? '' : 's')} in
            {' ' + getMarket(breadcrumb)}
          </SubTitle>
          <PricingDisclaimer>
            {`
            Prices and special offers valid for new residents only.
            Pricing and availability subject to change at any time.
            `}
          </PricingDisclaimer>
          <Grid.Row>
            {apartments.items.map((apartment, i) =>
              <Container.Card key={i} fullWidth={apartments.count === 1}>
                <ApartmentCard {...apartment}
                  layout={apartments.count === 1 ? 'row' : 'column'}
                  onClick={() => setFlyTo(apartment)}
                />
              </Container.Card>
            )}
          </Grid.Row>
          <Well copy={copy} />
          <RelatedLinks relatedPages={relatedPages} />
        </Grid.Col>
      </Container.Apartments>
    </Grid.Container>
  </>
}

export default Main
