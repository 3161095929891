import React from 'react'
import Markdown from 'react-markdown'
import styled from 'styled-components'
import Link from '../Link'

const Well = styled(Markdown).attrs({
  renderers: {
    link: Link
  },
})`
  background-color: ${ props => props.theme.well.backgroundColor };
  padding: 30px;
  color: ${ props => props.theme.well.bodyColor };
  margin-left: -15px;
  margin-right: -15px;
  font-size: 1.1em;
  font-family: ${ props => props.theme.fonts.header };

  h2, h3, h4, h5, h6 {
    color: ${ props => props.theme.well.headerColor };
    font-family: ${ props => props.theme.fonts.header };
  }

  h2 {
    font-size: 1.5em;
    font-weight: 700;
  }

  a {
    color: ${ props => props.theme.well.linkColor };
    text-decoration: none;
    transition: background .15s cubic-bezier(0.33, 0.66, 0.66, 1);
    border-bottom: 2px solid ${ props => props.theme.colors.primary };
    box-shadow: inset 0 -2px 0 ${ props => props.theme.colors.primary };
  }

  a:hover {
    background: ${ props => props.theme.colors.primary };
  }

  @media (min-width: 576px) {
    margin: 0;
  }
`

const WellContainer = ({ copy }) =>
  copy ? <Well source={copy} /> : null

export default WellContainer
