import React, { useState } from 'react'
import styled from 'styled-components'

import { sortBy } from '../../utils'
import Breadcrumb from '../Breadcrumb'
import Dropdown from '../Dropdown'
import Link from '../Search/Link'
import { Row } from '../Grid'

const Container = styled(Row)`
  margin: 0;
  padding: 10px 0;
  > *:first-child {
    width: calc(100% - 110px);
  }

  @media (min-width: 768px) {
    > * {
      flex: 1 1 50%;
    }

    > *:first-child {
      padding-right: 10px;
    }
  }

  @media (min-width: 992px) {
    > * {
      flex: 0 0 auto;
    }

    > *:first-child {
      width: 200px;
    }
  }
`

const Breadcrumbs = ({ market, submarket, ...props }) => {
  const [show, setShow] = useState(false)

  const handleKeyDown = e => e.key === 'Escape' && setShow(false)

  const submarkets = sortBy(props.submarkets, 'title')
    .filter(doc => doc.marketPage)

  const dropdownItems = [
    {
      component: Breadcrumb.Header,
      props: {
        children: market.state.name,
      }
    },
    {
      component: Dropdown.Item,
      props: {
        children: market.title,
        href: market.marketPage.slug,
        className: !submarket ? 'active' : '',
      }
    },
    ...submarkets.map(sm => ({
      component: Dropdown.Item,
      props: {
        children: sm.title,
        indent: 2,
        href: sm.marketPage.slug,
        className: submarket === sm ? 'active' : '',
      }
    })),
  ]

  return <Container>
    <Dropdown onKeyDown={handleKeyDown}
      onOutsideClick={() => setShow(false)}
    >
      <Breadcrumb.Toggle
        aria-expanded={show}
        aria-haspopup='true'
        onClick={() => setShow(!show)}
      >
        {(submarket || market).title}
      </Breadcrumb.Toggle>
      <Dropdown.Menu show={show}>
        {dropdownItems.map(
          ({ component: Component, props }, i) =>
            <Component key={i} {...props} />
        )}
      </Dropdown.Menu>
    </Dropdown>
    <Link market={market} submarket={submarket}>
      Advanced Search
    </Link>
  </Container>
}

export default Breadcrumbs
