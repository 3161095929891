import styled from 'styled-components'

export const Title = styled.h1`
  margin-bottom: 5px;
  font-family: ${ props => props.theme.fonts.header };
`

export const SubTitle = styled.small`
  color: ${ props => props.theme.main.bodyColor };
  display: block;
  font-size: 1.2em;
  padding-bottom: 15px;
`
